<template>
  <RevTextList variant="ordered">
    <template #default>
      <RevTextListItem
        v-for="{ title, paragraph, id } in instructions"
        :key="id"
        class="flex"
      >
        <div>
          <p class="text-action-default-hi body-1">{{ title }}</p>
          <p class="body-2 mt-1">
            {{ paragraph }}
          </p>
          <RevLink
            v-if="id === 'shipModeQrCode'"
            class="body-2"
            target="_blank"
            :to="i18n(translations.instructionQrCodeLink)"
          >
            {{ i18n(translations.instructionQrCodeLinkText) }}
          </RevLink>
        </div>
      </RevTextListItem>
    </template>
  </RevTextList>
</template>

<script setup lang="ts">
import { type ComputedRef, computed } from 'vue'

import { BUYBACK_SHIPPING_MODE } from '@backmarket/http-api/src/api-specs-buyback/constants'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevLink } from '@ds/components/Link'
import { RevTextList } from '@ds/components/TextList'
import { RevTextListItem } from '@ds/components/TextListItem'

import translations from './Instructions.translations'

interface InstructionsProps {
  price?: string
  model?: string
  daysLeft?: number
  shippingMode?: string
}

interface Instruction {
  id: string
  paragraph: string
  title: string
}

const props = defineProps<InstructionsProps>()
const i18n = useI18n()

const firstBlock = computed(() => {
  if (
    props.shippingMode === BUYBACK_SHIPPING_MODE.KIT ||
    props.shippingMode === BUYBACK_SHIPPING_MODE.NEW_KIT
  ) {
    return {
      id: 'shipMode',
      paragraph: i18n(translations.instructionReceiveKitParagraph),
      title: i18n(translations.instructionReceiveKitTitle),
    }
  }

  if (props.shippingMode === BUYBACK_SHIPPING_MODE.QR_CODE) {
    return {
      id: 'shipModeQrCode',
      paragraph: i18n(translations.instructionQrCodeParagraph),
      title: i18n(translations.instructionQrCodeTitle),
    }
  }

  return {
    id: 'shipMode',
    paragraph: i18n(translations.instructionPrintPrepaidLabelParagraph),
    title: i18n(translations.instructionPrintPrepaidLabelTitle),
  }
})

const instructions: ComputedRef<Array<Instruction>> = computed(() => {
  return [
    firstBlock.value,
    {
      id: 'delivery',
      title: i18n(translations.instructionShipItemTitle, {
        model: props.model,
        days: props.daysLeft,
      }),
      paragraph: i18n(translations.instructionShipItemParagraph),
    },
    {
      id: 'payment',
      title: i18n(translations.instructionPaymentTitle),
      paragraph: i18n(translations.instructionPaymentParagraph, {
        amount: props.price,
      }),
    },
  ]
})
</script>
