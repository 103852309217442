export default {
  instructionShipItemTitle: {
    id: 'buyback_confirmation_instruction_ship_item_title',
    defaultMessage: 'Ship your {model} within {days} days',
  },
  instructionShipItemParagraph: {
    id: 'buyback_confirmation_instruction_ship_item_paragraph',
    defaultMessage: 'Shipping is free!',
  },
  instructionPrintPrepaidLabelTitle: {
    id: 'buyback_confirmation_instruction_print_prepaid_label_title',
    defaultMessage: 'Print your prepaid shipping label',
  },
  instructionPrintPrepaidLabelParagraph: {
    id: 'buyback_confirmation_instruction_print_prepaid_label_paragraph',
    defaultMessage: 'Print it and stick it to your package.',
  },
  instructionReceiveKitTitle: {
    id: 'buyback_confirmation_instruction_receive_kit_title',
    defaultMessage: 'Receive your prepaid mailing kit',
  },
  instructionReceiveKitParagraph: {
    id: 'buyback_confirmation_instruction_receive_kit_paragraph',
    defaultMessage:
      'Your mailing kit will be delivered within 4 business days.',
  },
  instructionQrCodeTitle: {
    id: 'buyback_confirmation_instruction_qr_code_title',
    defaultMessage: 'Get your shipping label',
  },
  instructionQrCodeParagraph: {
    id: 'buyback_confirmation_instruction_qr_code_paragraph',
    defaultMessage:
      'In the next step, you’ll get your shipping label via your chosen method.',
  },
  instructionQrCodeLink: {
    id: 'buyback_confirmation_instruction_qr_code_link',
    defaultMessage: 'https://www.ups.com/mobile/locator',
  },
  instructionQrCodeLinkText: {
    id: 'buyback_confirmation_instruction_qr_code_link_text',
    defaultMessage: 'Find a store near me',
  },
  instructionPaymentTitle: {
    id: 'buyback_confirmation_instruction_payment_title',
    defaultMessage: 'Enter your banking and ID info',
  },
  instructionPaymentParagraph: {
    id: 'buyback_confirmation_instruction_payment_paragraph',
    defaultMessage:
      'You’ll see {amount} appear in your bank account once the refurbisher checks your item.',
  },
}
