<template>
  <div class="bg-surface-default-mid relative mb-48">
    <RevContainer
      class="bg-static-info-mid flex items-center justify-center p-32 md:p-56"
    >
      <div class="mx-auto flex max-w-[326px] flex-col items-center">
        <RevIllustration
          :alt="i18n(translations.imageAlt)"
          :height="180"
          :src="'/img/buyback/buybackFunnel.svg'"
          :width="329"
        />

        <h1 class="text-static-default-mid heading-1 mt-32 text-center">
          {{ i18n(translations.title) }}
        </h1>

        <span class="text-static-default-low body-1 mt-2 text-center">
          {{ i18n(translations.subtitle) }}
        </span>
        <div class="mt-32 hidden md:block md:w-full">
          <RevButton
            full-width="always"
            :to="{
              name: DASHBOARD.MY_SALES,
            }"
            variant="primary"
          >
            {{ i18n(translations.mySalesLink) }}
          </RevButton>
        </div>
      </div>
    </RevContainer>

    <RevContainer v-if="hasConfirmationDetails" class="mt-48 md:px-12">
      <h3 class="heading-3 mb-24">{{ i18n(translations.nextStepsTitle) }}</h3>
      <Instructions
        data-test="instructions"
        :days-left="confirmationDetails?.daysLeft"
        :model="confirmationDetails?.title"
        :price="confirmationDetails?.price"
        :shipping-mode="confirmationDetails?.shippingMode"
      />
      <RevInfoBlock
        class="mt-24 hidden md:flex"
        :icon="IconBell"
        :title="i18n(translations.infoBlockMessage)"
        variant="default"
      />
    </RevContainer>

    <RevContainer v-if="hasConfirmationDetails" class="mt-48 md:px-12">
      <h3 class="heading-3">{{ i18n(translations.saleInfo) }}</h3>
      <div class="mt-24 items-start gap-32 md:grid md:grid-cols-3">
        <RevCard class="p-32">
          <ProductInfo
            :image="productImage"
            :price="confirmationDetails?.price"
            :title="confirmationDetails?.title"
          />
          <RevList
            class="border-static-default-low border-b-1 md:border-b-0"
            :has-external-borders="false"
          >
            <RevListItemStatic
              v-for="item in diagnostingDetails"
              :key="item.id"
            >
              <template #label>
                <div class="flex justify-between">
                  <p class="body-2">{{ item.label }}</p>
                  <p class="body-2-bold text-right">{{ item.value }}</p>
                </div>
              </template>
            </RevListItemStatic>
            <RevListItemStatic v-for="item in orderDetails" :key="item.id">
              <template #label>
                <div class="flex justify-between">
                  <p class="body-2">{{ item.label }}</p>
                  <p class="body-2-bold text-right">{{ item.value }}</p>
                </div>
              </template>
            </RevListItemStatic>
          </RevList>
        </RevCard>
        <MobileAppBanner class="col-span-2" />
      </div>
    </RevContainer>

    <RevStickyBar class="md:hidden">
      <RevButton
        full-width="always"
        :to="{
          name: DASHBOARD.MY_SALES,
        }"
        variant="primary"
      >
        {{ i18n(translations.mySalesLink) }}
      </RevButton>
    </RevStickyBar>
  </div>
</template>

<script setup lang="ts">
import { useRouteParams } from '#imports'
import { computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'

import { getCustomerSaleDetails } from '@backmarket/http-api/src/api-specs-buyback/customer/getCustomerSaleDetails'
import ProductInfo from '@backmarket/nuxt-layer-buyback/components/ProductInfo/ProductInfo.vue'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButton } from '@ds/components/Button'
import { RevCard } from '@ds/components/Card'
import { RevContainer } from '@ds/components/Container'
import { RevIllustration } from '@ds/components/Illustration'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { RevList } from '@ds/components/List'
import { RevListItemStatic } from '@ds/components/ListItemStatic'
import { RevStickyBar } from '@ds/components/StickyBar'
import { IconBell } from '@ds/icons/IconBell'

import MobileAppBanner from '~/scopes/buyback/components/MobileAppBanner/MobileAppBanner.vue'
import { useBuyBackStore } from '~/scopes/buyback/store'
import { DASHBOARD } from '~/scopes/dashboard/routes'

import { confirmationDetailsAdapter } from '../adapters/confirmationDetails'
import Instructions from '../components/Instructions/Instructions.vue'

import translations from './Confirmation.translations'

const tracking = useTracking()

const route = useRoute()
const i18n = useI18n()
const routeParams = useRouteParams()
const store = useBuyBackStore()

const { data } = await useHttpFetch(getCustomerSaleDetails, {
  pathParams: {
    id: routeParams?.id,
  },
})

const confirmationDetails = computed(() => {
  if (data.value) {
    return confirmationDetailsAdapter(data.value)
  }

  return null
})

const hasConfirmationDetails = computed(
  () =>
    confirmationDetails.value?.title &&
    confirmationDetails.value?.price &&
    confirmationDetails.value?.shippingMode &&
    confirmationDetails.value?.details,
)

const productImage = computed(() => {
  return hasConfirmationDetails.value && confirmationDetails.value?.image?.light
})

const diagnostingDetails = computed(() => {
  return confirmationDetails.value?.details[0]
})

const orderDetails = computed(() => {
  return confirmationDetails.value?.details[1]
})

onMounted(() => {
  store.resetBuyBackOffer()

  if (route?.query?.partner) {
    tracking.trackBuyBackPlayStationPartnership()
  }
})
</script>
